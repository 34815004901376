.program-bg {
    height: 100%;
    overflow: hidden;
    &.pro {
        position: relative;

        &::before {
            content: 'PRO';
            display: inline-block;
            padding: 2px 14px;
            position: absolute;
            z-index: 1;
            left: 0px;
            top: 0px;
            color: var(--primary-white);
            background-color: var(--primary-color);
        }
    }
}
