html,
body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: fixed;
    padding: 0 0 env(safe-area-inset-bottom, 0.5vh) 0;
}

body {
    -webkit-overflow-scrolling: touch;
}

.block-pull-refresh {
    overscroll-behavior: contain !important;
}

h2 {
    color: var(--primary-white);
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;

    &.larger {
        font-size: 30px;
        line-height: 30px;
        z-index: 9;
    }
}

h3 {
    color: var(--primary-white);
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

h4 {
    color: var(--primary-white);
    font-size: 18px;
    line-height: 26px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

h5 {
    color: var(--primary-white);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

p {
    color: var(--primary-white);
    font-size: 14px;
    line-height: 18px;
    font-family: 'Montserrat', sans-serif;

    &.gray {
        color: var(--secondary-grey);
    }

    &.smaller {
        font-size: 12px;
        line-height: 20px;
    }
}

span {
    color: var(--primary-white);
    font-size: 12px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.btn {
    font-family: 'Montserrat', sans-serif;
}

img {
    max-width: 100%;

    &.object-fit-cover {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.color-white {
    color: var(--primary-white);
}

.color-primary {
    color: var(--primary-color) !important;
}

a {
    color: var(--secondary-grey);
    font-size: 14px;
    line-height: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-decoration: none;

    &:hover {
        color: var(--secondary-grey);
        text-decoration: none;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.mt-28 {
    margin-top: 28px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-12 {
    margin-top: 12px;
}

.mr-12 {
    margin-right: 12px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-40 {
    margin-bottom: 40px;
}

.text-right {
    text-align: right;
}

.progress {
    height: 6px;
    border-radius: 3px;

    .progress-bar {
        background: var(--primary-color);
    }
}

.badge {
    background: var(--secondary-grey);
    border-radius: 15px;
    padding: 6px 12px;
    margin-right: 8px;
    margin-top: 8px;
}

.white-label-bg-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &.no-activated-programs {
        background: none !important;

        .header-section {
            padding-bottom: 20px;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, var(--primary-rgb-dark) 0%, var(--primary-dark) 100%);
    }
}

.video-player {
    width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &.ordered-list {
        list-style-type: decimal;
        padding-left: 15px;

        li {
            color: var(--primary-white);
            font-size: 14px;
            line-height: 22px;
            font-family: 'Montserrat', sans-serif;
            margin-bottom: 16px;
        }
    }

    &.with-checkmark {
        li {
            color: var(--secondary-grey);
            font-size: 14px;
            line-height: 24px;
            font-family: 'Montserrat', sans-serif;
            margin-bottom: 12px;
            padding-left: 36px;
            position: relative;

            svg-icon {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                left: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

        }
    }
}

.ios-platform {
    padding: 0 0 70px !important;

    app-plans,
    app-list,
    app-plan-details,
    app-recipe-books,
    app-profile,
    app-exercises,
    app-settings {
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        max-width: 450px;
        margin: 0 auto;
    }

    app-plan-details,
    app-workout-details {
        overflow-y: auto;
        padding-bottom: 80px;
    }

    exercise-set-finished {
        .mx-auto.w-100.ps-3 {
            bottom: 80px;
        }

    }

    .bottom-navigation {
        padding: 9px 0 15px 0 !important;
    }

    .workout-finished {
        padding-bottom: 85px !important;

        .bottom-actions-fixed {
            bottom: 70px;
        }
    }

    .bottom-actions-fixed {
        z-index: 100000;

        button {
            z-index: 1000;
            color: #fff;
            background-color: var(--primary-color) !important;
        }
    }

    &.hide-navigation {
        padding: 0;
    }
}


app-plans,
app-list,
app-plan-details,
app-recipe-books,
app-profile,
app-exercises,
app-settings {
    width: 100%;
    position: relative;

}

back-button {
    height: 24px;
}

app-settings {
    height: 100%;
}

.swipe-item{
    width: 100%;
}

svg-icon {
    min-height: 18px;
}

app-exercises {
    height: 100%;
}

app-plans,
app-recipe-books {
    height: 100%;
    overflow-y: auto;
}

plans-list,
exercise-list,
app-list-workouts {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.bottom-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    width: 100%;
    background: var(--primary-dark);
    padding: 9px 0;
    max-width: 450px;
    box-shadow: 0 -5px 10px #0f1520;
    display: flex;

    .nav-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;

        a {
            box-shadow: none;
            outline: none;
            border: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                font-size: 11px;
                margin: 5px 0 0 0;
                line-height: 14px;
                color: var(--secondary-grey);
            }

            &.active {
                p {
                    color: var(--primary-color) !important;
                }
            }
        }
    }
}

app-home {
    width: 100%;
}

hr.hr {
    border-bottom: 1px solid var(--primary-grey);
}

timer.small {
    transform: scale(0.6);
}

timer {
    width: 150px;
    margin-top: 50px;
    margin-bottom: -39px;
    height: 150px;
}

.app-container {
    max-width: 450px;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: var(--primary-dark);
    background: linear-gradient(180deg, var(--primary-rgb-dark) 0%, var(--primary-dark) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.white-label-bg {
        background-size: cover;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, var(--primary-rgb-dark) 0%, var(--primary-dark) 100%);
        }
    }

    &.with-color-bg {
        background: var(--primary-dark);
    }

    .powered-by {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 32px;
    }
}

.header-section {
    display: flex;
    width: 100%;
    position: absolute;
    top: 36px;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;

    .back-button {
        position: absolute;
        left: 15px;
    }

    h5 {
        margin: 0;
    }

    &.fixed-header {
        background: var(--primary-dark);
        position: fixed;
        top: 0;
        padding: 22px 0;
        z-index: 999;
        margin: 0 auto;
        max-width: 450px;
    }
}


input {
    &.text-input {
        padding: 15px 0 5px 0;
        width: 100%;
        color: var(--primary-white);
        font-size: 14px;
        line-height: 22px;
        font-family: 'Montserrat', sans-serif;
        border: none;
        border-bottom: 2px solid var(--secondary-grey);
        background: transparent;
        box-shadow: none;
        outline: none;

        &:focus {
            border-bottom: 2px solid var(--primary-white);
        }
    }
}

.input-password-eye {
    position: relative;

    .eye-icon {
        position: absolute;
        background-size: contain;
        right: 10px;
        top: 11px;
        font-weight: 800;
        font-size: 20px;
        opacity: 1;
        width: 24px;
        height: 24px;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
}

.input-checkbox-wrapper {
    position: relative;

    svg-icon {
        position: absolute;
        top: -4px;
        left: -2px;
        pointer-events: none;
    }
}

.input-checkbox {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    display: flex;

    &.true,
    &.false {
        cursor: pointer;
        border: 1px solid var(--primary-color);
        border-radius: 2px;
        transition: background-color 0.2s linear;
    }

    &.true {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        transition: background-color 0.2s linear;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}


input::-webkit-input-placeholder {
    font-family: 'Montserrat', sans-serif;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

input:focus::-webkit-input-placeholder,
input:valid::-webkit-input-placeholder {
    color: var(--primary-white);
    font-size: 12px;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    visibility: visible !important;
}


.form-group-row {
    display: flex;
    width: 100%;
    margin-bottom: 48px;

    .form-group-column {
        display: flex;
        width: 50%;

        &:first-of-type {
            padding-right: 5px;
        }

        &:last-of-type {
            padding-left: 5px;
        }
    }
}

.input-password-eye {
    width: 100%;
}

.bottom-actions {
    position: absolute;
    bottom: 24px;
    left: 15px;
    right: 15px;
}

.bottom-actions-fixed {
    position: fixed;
    margin: 0 auto;
    bottom: 0px;
    z-index: 100;
    left: 0;
    right: 0;
    padding: 0 15px 15px;
    width: 100vw !important;
    max-width: 450px;
    background: var(--primary-dark);
}

.activated-programs {
    padding: 15px 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .activated-programs-description {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 160px;
        align-items: center;
        padding-bottom: 40px;

        .button {
            margin-top: 28px;
        }

        .week-day-box {
            width: 100%;
            display: flex;

            p {
                display: flex;
                width: 100%;
                margin: 0;
                padding: 0;

                &:first-of-type {
                    justify-content: center;
                    padding-right: 4px;
                }
            }
        }

        .duration-levels-box {
            width: 100%;
            display: flex;

            p {
                display: flex;
                width: 50%;
                margin: 0;
                padding: 0 10px;

                &:first-of-type {
                    border-right: 1px solid var(--primary-color);
                    justify-content: flex-end;

                }
            }
        }
    }
}

.see-plan-info {
    position: relative;
    color: var(--primary-white);
    font-size: 16px;
    line-height: 24px;
    width: 100%;

    &:hover {
        color: var(--primary-white);
    }

    svg-icon {
        transform: rotate(-90deg);
        width: 23px;
        height: 13px;
        position: absolute;
        top: 11px;
        right: 0;
    }
}

.see-plan-info-box {
    display: flex;
    width: 100%;
    padding: 0 15px 28px;
}

.programs,
.recipe-books {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 15px;

    &.live-events {
        .program-bg {
            height: 130px;
            position: relative;

            &:after {
                display: none;
            }

            h5 {
                z-index: 9;
            }

            .program-desc {
                flex-direction: column;

                p {
                    border-right: 0;
                    padding: 0;
                }
            }

            &.pro {
                content: 'PRO';
                position: absolute;
                padding: 3px 6px;
                color: #fff;
                display: inline-block;
                left: 0;
                top: 0;
                background-color: var(--primary-color);
            }
        }

        .live-event-desc {
            padding: 20px 0;
            display: flex;
            width: 100%;
            flex-direction: column;
            background: var(--secondary-dark);

            .program-desc {
                padding: 0 16px;
            }

            svg-icon {
                margin-right: 8px;
            }
        }

        .live-event-bottom-action {
            border-top: 1px solid var(--primary-grey);
            padding: 16px 16px 0;
        }
    }

    .program-bg {
        display: flex;
        width: 100%;
        height: 240px;
        padding: 12px;
        border-radius: 10px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        overflow: hidden;

        h2 {
            margin-bottom: 16px;
            text-align: left;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, var(--secondary-04-dark) 0%, var(--secondary-08-dark) 100%);
            border-radius: 10px;
        }

        .program-desc {
            display: flex;
            width: 100%;

            p {
                white-space: nowrap;
                z-index: 9;
                margin: 0;
                padding: 0 10px;
                border-right: 1px solid var(--primary-color);

                &:first-of-type {
                    padding-left: 0;
                }

                &:last-of-type {
                    border-right: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }

            }
        }

        &.no-mask {
            &::after {
                display: none;
            }
        }

        .top-badge {
            position: absolute;
            top: 0px;
            left: 0px;
            padding: 5px 14px;
            z-index: 9;
            background: var(--primary-color);

            span {
                color: var(--primary-white);
            }
        }
    }
}

.prev-button {
    box-shadow: none;
    border: none;
    outline: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 8;
    background: none;
    margin-top: -5px;

}

.next-button {
    box-shadow: none;
    border: none;
    outline: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 8;
    background: none;
    margin-top: -5px;

}


.muscle-groups {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    &.training-types {
        .muscle-group {
            .muscle-group-inner {
                .muscle-group-img {
                    background-size: cover;
                    display: flex;
                }

                h5 {
                    display: flex;
                    padding: 0 10px;
                    justify-content: center;
                    height: 48px;
                    align-items: center;
                }
            }
        }
    }

    .muscle-group {
        width: 33.3334%;
        display: flex;
        padding: 5px;

        &:nth-of-type(3n+3) {
            padding-right: 0;
        }

        &:nth-of-type(3n+1) {
            padding-left: 0;
        }

        a {
            display: flex;
            width: 100%;
        }

        .muscle-group-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            background: var(--secondary-dark);
            text-align: center;
            border-radius: 2px;

            h5 {
                margin: 12px 0;
            }

            .muscle-group-img {
                height: 96px;
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}

.recipe-items {

    .program-bg {
        display: flex;
        width: 100%;
        height: 180px;
        padding: 12px;
        border-radius: 2px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }
}

.plan-img {
    height: 222px;
}

.after-fixed-header-small {
    margin-top: 68px;
}

.after-fixed-header-medium {
    margin-top: 138px;
}

.after-fixed-header-big {
    margin-top: 199px;
}

.plan-details {
    padding: 28px 15px 28px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    border-bottom: 1px solid var(--primary-grey);
    position: relative;

    h2 {
        text-align: left;
    }

    &.with-bg {
        background: var(--primary-dark);
    }
}

.plan-schedule-box {
    border-radius: 2px;
    background: var(--secondary-dark);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 28px;

    .schedule-part {
        display: flex;
        width: 100%;
        flex-direction: column;

        &.multiple-schedule {
            padding: 0 0 28px 33px;
            position: relative;

            &:after {
                content: '';
                width: 13px;
                height: 13px;
                top: calc(50% - 15px);
                position: absolute;
                left: 0;
                border-radius: 50%;
                border: 1px solid var(--primary-white);
                background: var(--secondary-dark);
            }

            &:before {
                content: '';
                position: absolute;
                width: 1px;
                height: calc(100% - 12px);
                top: calc(50% - 3px);
                left: 6px;
                background: var(--primary-white);
            }

            &:first-of-type {
                padding-top: 0;
            }

            &:last-of-type {
                padding-bottom: 0;

                &:after {
                    top: calc(50% - 7px);
                }

                &:before {
                    display: none;
                }
            }
        }
    }
}

.plan-schedule {
    padding: 28px 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;

    &.with-bg {
        background: var(--primary-dark);
    }

    h5 {
        padding: 0 15px;
    }

    .card {
        background-color: var(--secondary-dark);
        border: none;
        border-radius: 0;
        margin-bottom: 16px;

        .card-header {
            border-radius: 0;
            padding: 0;
        }

        .button-link {
            display: flex;
            width: 100%;
            padding: 20px 15px;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0;
                display: inline-flex;
                align-items: center;
                position: relative;
                min-width: 60px;
            }

            svg-icon {
                width: 28px;
                height: 23px;
            }
        }

        .panel-body {
            background: var(--primary-dark);
            padding: 10px 0;
        }
    }

    .round-name {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0 15px;
    }
}

.chevron-indicator {
    width: 23px;
    height: 23px;
    display: flex;
    transition: transform 0.2s linear;
    transform: rotate(180deg);

    &.closed {
        transform: rotate(0deg);
    }

}

.schedule-day {
    display: flex;
    width: 100%;
    padding: 6px 15px 6px 11px;
    margin-bottom: 10px;
    border-left: 4px solid transparent;

    .top-badge {
        position: absolute;
        top: 0;
        left: 0;
        padding: 2px 16px;
        z-index: 9;
        background: var(--primary-color);

        span {
            color: var(--primary-white);
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &.item-completed {

        .schedule-img {

            &::before,
            &::after {
                content: '';
                display: inline-block;
                width: 100%;
                height: 100%;
                position: absolute;
                background-position: center center;
                background-repeat: no-repeat;
                left: 0;
                top: 0;
            }

            &::before {
                background-color: var(--primary-color);
                opacity: 0.75;
            }

            svg-icon {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    .schedule-img {
        display: flex;
        position: relative;
        width: 83px;
        min-width: 83px;
        height: 75px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .schedule-rh {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-left: 12px;
        justify-content: center;
        overflow: hidden;

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: inline-block;
        }
    }


    .schedule-desc {
        display: flex;
        width: 100%;


        p {
            margin: 0;
            padding: 0 10px;
            border-right: 1px solid var(--primary-color);
            width: auto;
            overflow: initial;

            &:first-of-type {
                padding-left: 0;
                white-space: nowrap;
            }

            &:last-of-type {
                border-right: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: inline-block;
            }

        }
    }

    &.current-workout {
        background: #d9e0ec1a;
        border-left-color: var(--primary-color);
    }
}

.modal {
    background: var(--primary-rgb-dark);

    &.show {
        .modal-dialog {
            top: 50%;
            transform: translate(0, -110px);
        }
    }
}

.modal-dialog {
    max-width: 450px;
    width: 90%;

    .modal-content {
        border-radius: 2px;
        border: none;
        width: 100%;
        background-color: var(--secondary-dark);
        box-shadow: 0px 3px 10px #9797971A;

        .modal-header {
            border-bottom: none;
            padding: 20px 20px 28px;

            h4 {
                margin: 0;
            }
        }

        .modal-body {
            border-bottom: none;
            padding: 0 20px 20px;

            p {
                font-size: 14px;
                color: var(--secondary-grey);
                line-height: 22px;
            }
        }

        .modal-footer {
            border-top: none;
            padding: 8px 20px 20px;

            .button-link {
                color: var(--primary-white);
            }
        }
    }
}

app-infinite-scroll {
    display: flex;
    width: 100%;
    height: 100%;
}

.infinite-scroll-container {
    height: 100%;
    max-height: calc(100vh - 140px);
    display: flex;
    overflow-y: auto;
    width: 100%;
    flex-direction: column;
}

app-onboarding {
    ngu-carousel {
        display: flex;
        height: 100%;
        flex-direction: column;
    }

    ngu-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 15px;

        h3,
        p {
            position: relative;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, var(--primary-rgb-dark) 0%, var(--primary-dark) 57%);
        }
    }

    .ngu-touch-container {
        height: 100%;
    }
}

@media only screen and (orientation:landscape) and (min-width: 576px) and (max-width: 900px){
    body {
        width: 100vh;
        height: 100vw;
        transform: rotate(-90deg);
        top: 0;
        left: 0;
        right: 0px;
        bottom: 0;
        margin: auto;
    }

    .app-container {
        height: auto;
    }

    .infinite-scroll-container {
        max-height: calc(100vw - 140px);
    }

    app-onboarding {
        .h-100 {
            height: 100vw !important;
        }
    }
}
