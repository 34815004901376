.button {
    border-radius: 10px;
    color: var(--primary-white);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    padding: 15px 20px;
    width: 100%;
    border: 1px solid var(--primary-white);
    background: transparent;
    font-weight: 500;
    text-align: center;

    &.primary {
        border-color: var(--primary-color);
        background: var(--primary-color);
        z-index: 99;

        &:hover {
            color: var(--primary-white);
        }

        &.email {
            position: relative;
            font-size: 14px;
            line-height: 22px;
            padding: 12px 20px;
            margin-top: 28px;

            svg-icon {
                position: absolute;
                width: 24px;
                height: 24px;
                left: 16px;
                top: calc(50% - 12px);
            }
        }

        &:disabled {
            background: var(--primary-grey);
            border-color: var(--primary-grey);
            color: #D9E0EC;
            cursor: not-allowed;
        }
    }

    &.facebook {
        border-color: #3B5998 !important;
        background: #3B5998 !important;
        color: var(--primary-white) !important;
        position: relative;
        font-size: 14px;
        line-height: 22px;
        padding: 12px 20px;
        margin-bottom: 12px;

        svg-icon {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 16px;
            top: calc(50% - 12px);
        }
    }

    &.google {
        background: var(--primary-white) !important;
        color: var(--secondary-grey) !important;
        border: 1px solid var(--primary-grey) !important;
        position: relative;
        font-size: 14px;
        line-height: 22px;
        padding: 12px 20px;
        margin-bottom: 28px;

        img {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 16px;
            top: calc(50% - 10px);
        }
    }

    &.with-icon {
        position: relative;

        svg-icon {
            position: absolute;
            left: 15px;
            top: calc(50% - 13px);
        }
    }

}

.back-button {
    background: none;
    padding: 0;
    margin: 0;
    width: 24px;
    height: 24px;
    box-shadow: none;
    outline: none;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &-rounded {
        padding: 5px;
        border-radius: 50%;
        background: var(--secondary-grey);
    }
}

.button-link {
    box-shadow: none;
    border: none;
    background: none;
    outline: none;
    color: var(--secondary-grey);
    text-align: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;

    &.primary {
        color: var(--error-text-color);
    }
}

.svg-button {
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
}

.btn-group.level-filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    .btn-primary {
        display: flex;
        border-radius: 15px !important;
        color: var(--secondary-grey);
        font-size: 12px;
        background: var(--secondary-dark);
        border-color: var(--secondary-dark);
        box-shadow: none;
        padding: 5px 10px;
        flex: 0;
        white-space: nowrap;

        &.active {
            background: var(--primary-color);
            border-color: var(--primary-color);
            color: var(--primary-white);
        }
    }
}