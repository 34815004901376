.exercise-backdrop {
    width: 100%;
    height: 100vh;
    max-width: 450px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--primary-dark);
    opacity: 0.7;
}


.item {

    &-completed {
        opacity: .7;

        .schedule-img {
            position: relative;

            &::before {
                content: '';
                display: inline-block;
                width: 100%;
                height: 100%;
                background-color: var(--primary-color);
                opacity: 0.75;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    &-not-completed {
        &:first-of-type {
            border-left: 4px solid var(--primary-color) !important;
        }
    }
}

exercise-manager {
    .exercise-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        height: calc(100vh - 88px);

        &.with-preparation-time {
            div:last-child {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 25px;
            }

            p.rest {
                margin-bottom: 25px;
                font-size: 22px;
            }
        }

        &.set-finished,
        &.feedback-wrapper,
        .workout-finished {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            height: calc(100vh - 48px);

            .bottom {
                padding: 0 15px;
                width: 100%;
            }
        }

        .workout-finished {
            height: 100%;
        }

        video {
            object-fit: cover;
        }

        .bottom {
            width: 100%;
            padding: 20px;
            text-align: center;
            margin-bottom: -38px
        }

        .bottom {
            padding: 0 15px;
            width: 100%;
        }

        &.pause {
            justify-content: center;
        }


        .workout-finished {
            height: 100%;
            padding-bottom: 35px;

            &.feedback-wrapper {
                .close {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px 15px;

                    span {
                        font-size: 20px;
                    }


                }

                .feedback-pick {
                    width: 100%;
                    min-height: 300px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .form {
                        width: 100%;
                        padding: 0px 20px;
                        min-height: 300px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .item {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .icons-wrapper,
                            .stars-wrapper {
                                width: 250px;
                                display: flex;
                                justify-content: space-between;

                                label.radio-image,
                                label.radio-star {
                                    width: 40px;
                                    height: 40px;
                                    display: inline-block;
                                    padding: 0;
                                    background-size: 100% auto;
                                    background-position: center center;
                                    background-repeat: no-repeat;
                                    border-radius: 50%;
                                    position: relative;

                                    svg-icon {
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        width: 36px;
                                        height: 36px;

                                        svg {
                                            width: 36px;
                                            height: 36px;
                                        }
                                    }
                                }

                                &.dificulty {
                                    label.radio-image {
                                        &.selected {
                                            border: 2px solid var(--primary-color);
                                        }
                                    }
                                }

                                &.score {
                                    label.radio-image {
                                        &.selected {
                                            border: 2px solid var(--primary-color);
                                        }
                                    }
                                }

                                label.radio-star {
                                    width: 24px;
                                    height: 24px;
                                    margin-right: 6px;

                                    svg-icon {
                                        width: 24px;
                                        height: 24px;

                                        svg {
                                            width: 24px;
                                            height: 24px;
                                        }
                                    }
                                }


                                input {
                                    visibility: hidden;
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .stars-wrapper {
                                justify-content: center;
                            }

                        }

                        textarea {
                            width: 100%;
                            min-height: 100px;
                            padding: 10px;
                            background-color: var(--secondary-dark);
                            border: none;
                            color: var(--primary-white);

                            &:focus-visible {
                                outline: none;
                            }
                        }
                    }
                }
            }

            .completed-badge {
                width: 100%;
                margin-top: -100px;
                display: flex;
                justify-content: center;


            }

            .completed-details {
                display: flex;
                width: 100%;
                padding-bottom: 15px;

                .item {
                    width: 33.33%;
                    display: flex;
                    flex-direction: column;

                    h3 {
                        font-size: 30px;
                        margin-top: 12px;
                    }

                    &:first-child {
                        border-right: 1px solid var(--primary-color);
                    }

                    &:last-child {
                        border-left: 1px solid var(--primary-color);
                    }
                }
            }
        }




        span.next-set-info {
            display: inline-block;
            margin: 0 5px;
            padding: 3px 5px;
            border-radius: 25px;
            background-color: var(--primary-grey);
            color: var(--primary-white);
        }



        p.rest {
            font-weight: 500;
            font-size: 24px;
            color: var(--primary-white);
        }

        .description {
            width: 100%;
            display: flex;
            padding-top: 20px;
            bottom: 60px;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            &.current {
                box-shadow: 0px 0px 20px 30px var(--primary-dark);
                bottom: 128px;
            }


            .workout-label {
                display: flex;
                align-items: center;

                &-name {
                    font-size: 1.1rem;
                    font-weight: 600;
                }

                &-icon {
                    display: inline-flex;
                    margin-left: 15px;
                    width: 24px;
                    height: 24px;
                }

                &-percent {
                    font-size: 15px;
                    font-weight: 500;
                }

                &-reps {
                    font-size: 45px;
                    line-height: normal;
                    letter-spacing: 6px;
                }

                &-times {
                    font-size: 30px;
                }
            }
        }

    }
}


.loading-workout-overlay {
    position: fixed;
    width: 100vw;
    max-width: 450px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
}


.tracking-progress {
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    display: flex;
    align-items: center;

    .tracking-item {
        height: 4px;
        width: 100%;
        margin: 0px 2px 0px 0px;
        border-radius: 2px;
        border: 1px solid #808080;

        &.finished {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
        }

        &.current {
            background-color: var(--primary-white);
            border-color: var(--primary-white);
        }


    }
}

.name-and-rounds {
    display: flex;
    width: 100%;

    .close-workout {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary-white);
        font-size: 16px;
        font-weight: 900;
    }

    .name-and-rounds-description {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;

        .title {
            font-weight: 600;
            font-size: 14px;
            color: var(--primary-white);
        }

        .rounds {
            opacity: 0.7;
        }
    }
}

.multiline-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}


.multiline-text {
    display: flex;
    width: 100%;
    min-height: 18px;
    margin-bottom: 0;
    font-size: inherit;
    color: var(--secondary-grey);
}

.bottom-actions-fixed {

    .limit-exceeded {
        background-color: var(--secondary-grey);
        border-color: var(--secondary-grey);
    }



    .cant-activate-message {
        position: absolute;
        padding: 10px 15px;
        background-color: var(--secondary-grey);
        color: #fff;
        text-align: center;
        width: 100%;
        max-width: 310px;
        top: -70px;
        left: 50%;
        transform: translateX(-50%);
    }
}