// :root {
//     --primary-color: #FF9D33;
//     --secondary-color: #FFB83F;
//     --primary-dark: #24405B;
//     --primary-0-rgb-dark: rgba(36, 64, 91, 0);
//     --primary-02-rgb-dark: rgba(36, 64, 91, 0.2);
//     --primary-03-rgb-dark: rgba(36, 64, 91, 0.3);
//     --primary-06-rgb-dark: rgba(36, 64, 91, 0.6);
//     --primary-09-rgb-dark: rgba(36, 64, 91, 0.9);
//     --primary-rgb-dark: rgba(36, 64, 91, 0.8);
//     --secondary-dark: #93AFCF;
//     --secondary-04-dark: rgba(147, 175, 207, 0.4);
//     --secondary-08-dark: rgba(147, 175, 207, 0.8);
//     --primary-grey: #5D798F;
//     --secondary-grey: #C6D2E1;
//     --tertiary-grey: #EEEEEF;
//     --primary-white: #FFFFFF;
//     --primary-rgb-white: rgba(255, 255, 255, 0.8);
//     --primary-0-rgb-white: rgba(255, 255, 255, 0);
//     --secondary-white: #FCFCFC;
//     --error-text-color: #DE1D1D;
// }

:root {
    --primary-color: #FF6156;
    --secondary-color: #FF7972;
    --primary-dark: #121926;
    --primary-0-rgb-dark: rgba(18, 25, 39, 0);
    --primary-02-rgb-dark: rgba(18, 25, 39, 0.2);
    --primary-03-rgb-dark: rgba(18, 25, 39, 0.3);
    --primary-06-rgb-dark: rgba(18, 25, 39, 0.6);
    --primary-09-rgb-dark: rgba(18, 25, 39, 0.9);
    --primary-rgb-dark: rgba(18, 25, 39, 0.8);
    --secondary-dark: #1E2637;
    --secondary-04-dark: rgba(30, 38, 55, 0.4);
    --secondary-08-dark: rgba(30, 38, 55, 0.8);
    --primary-grey: #6A768B;
    --secondary-grey: #8D99AE;
    --tertiary-grey: #EEEEEF;
    --primary-white: #FFFFFF;
    --primary-rgb-white: rgba(255, 255, 255, 0.8);
    --primary-0-rgb-white: rgba(255, 255, 255, 0);
    --secondary-white: #FCFCFC;
    --error-text-color: #FF3434;
}

.light-theme {
    .app-container {
        &.with-color-bg {
            background: var(--primary-white);
        }

        &.white-label-bg {
            background-size: cover;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(180deg, #FCFCFC00 0%, #FCFCFCB3 47%, #FCFCFC 57%);
            }
        }
    }

    ol {
        color: var(--primary-grey);
    }

    .button {
        color: var(--primary-color);
        border: 1px solid var(--primary-color);

        &.primary {
            color: var(--primary-white);
        }

        &.google {
            color: var(--primary-dark) !important;
        }
    }

    h2 {
        color: var(--primary-dark);
    }

    h3 {
        color: var(--primary-dark);
    }

    h4 {
        color: var(--primary-dark);
    }

    h5 {
        color: var(--primary-dark);

        span {
            color: var(--primary-dark);
        }
    }

    span {
        color: var(--primary-grey);
    }

    p {
        color: var(--primary-grey);

        &.gray {
            color: var(--primary-grey);
        }
    }

    .carousel-points {
        li {
            background: #717171 !important;

            &.active {
                background: var(--primary-color) !important;
            }
        }
    }

    input {
        &.text-input {
            color: var(--primary-dark);
            border-bottom: 2px solid var(--primary-dark);
        }
    }

    .button-link {
        color: var(--primary-grey);

        &.primary {
            color: var(--error-text-color);
        }
    }

    .white-label-bg-top {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, #FCFCFC00 0%, #FCFCFCB3 38%, #FCFCFC 55%);
        }

        &.no-activated-programs {
            background: none !important;
        }
    }

    .programs,
    .recipe-books {
        .program-bg {
            &::after {
                background: linear-gradient(180deg, var(--primary-0-rgb-dark) 0%, var(--primary-06-rgb-dark) 100%);
            }

            h2 {
                color: var(--primary-white);
            }

            p {
                color: var(--primary-white);
            }
        }
    }

    .bottom-navigation {
        background: var(--primary-white);
        box-shadow: 0px -5px 10px rgba(93, 121, 143, 0.12);

        .nav-item {
            a {
                p {
                    color: var(--primary-grey);
                }
            }
        }
    }

    .muscle-groups {
        .muscle-group {
            .muscle-group-inner {
                background: var(--primary-white);
                box-shadow: 0px 5px 10px rgb(93 121 143 / 30%);
                border-radius: 10px;
            }
        }
    }

    .header-section {
        &.fixed-header {
            background: var(--primary-white);
        }
    }

    .bottom-actions-fixed {
        background: var(--primary-white);
    }

    .plan-schedule {
        .card {
            background-color: var(--secondary-white);

            .card-header {
                background-color: transparent;
                border-bottom: 0;
            }

            .panel-body {
                background: var(--primary-white);
            }
        }
    }

    .main-training-details {
        background: var(--primary-white) !important;
        background: linear-gradient(180deg, rgba(23, 24, 25, 0) 0%, rgba(18, 25, 39, 0) 35%, var(--primary-rgb-white) 92%, var(--primary-white) 100%) !important;

        p {
            color: var(--primary-white);
        }
    }

    .training-details {
        background: var(--primary-white) !important;
    }

    .plan-details {
        &.with-bg {
            background: var(--primary-white);
        }
    }

    .plan-schedule {
        &.with-bg {
            background: var(--primary-white);
            box-shadow: none !important;
        }
    }

    .name-and-rounds {
        .close-workout {
            color: var(--primary-dark);
        }

        .name-and-rounds-description {
            .title {
                color: var(--primary-dark);
            }
        }
    }

    exercise-manager {
        .exercise-wrapper {
            .description.current {
                box-shadow: 0px 0px 20px 30px var(--primary-white);
            }
        }
    }

    .bottomSheet {
        background: var(--tertiary-grey) !important;

        .swipe-handler {
            &::after {
                background: var(--primary-dark) !important;
            }
        }
    }

    .modal-dialog {
        .modal-header {
            h4 {
                color: var(--primary-white);
            }
        }
    }

    .tabs {
        .tab {
            background-color: var(--primary-dark) !important;
            color: var(--primary-white) !important;

            &.active {
                background-color: var(--primary-color) !important;
                color: var(--primary-white) !important;
            }
        }
    }

    .btn-group {
        &.level-filters {
            .btn-primary {
                color: var(--primary-white) !important;
                background-color: var(--secondary-grey) !important;
                border-color: var(--secondary-grey) !important;

                &.active {
                    background-color: var(--primary-color) !important;
                    border-color: var(--primary-color) !important;
                    color: var(--primary-white);
                }
            }
        }
    }

    .upgrade-overlay {
        background-image: linear-gradient(180deg, #FCFCFC00 0%, #FCFCFCB3 47%, #FCFCFC 65%) !important;

        .upgrade-box {
            span {
                color: var(--primary-grey);
            }
        }
    }

    // svg-icon {
    //     --secondary-grey: var(--primary-grey);
    // }

    // svg-icon {
    //     --primary-white: var(--primary-dark);
    // }

    app-onboarding {
        ngu-item {
            &::before {
                background: linear-gradient(180deg, #FCFCFC00 0%, #FCFCFCB3 47%, #FCFCFC 57%);
            }
        }
    }

    .plan-schedule-box {
        background: var(--tertiary-grey);

        .schedule-part {
            &.multiple-schedule {
                &:after {
                    border: 1px solid var(--primary-grey);
                    background: var(--tertiary-grey);
                }

                &:before {
                    background: var(--primary-grey);
                }
            }
        }
    }

    .see-plan-info {
        color: var(--primary-dark);

        &:hover {
            color: var(--primary-dark);
        }
    }

    ul {
        &.ordered-list {
            li {
                color: var(--primary-grey);
            }
        }
    }

    .nutritiens {
        .nutritiens-wrapper {
            .nutritiens-item {
                .counter-item {
                    color: var(--primary-grey) !important;
                }

                .bold {
                    color: var(--primary-grey) !important;
                }
            }
        }
    }

    .simple-picker {
        background-color: var(--secondary-grey) !important;

        h5 {
            color: var(--primary-white);
        }
    }

    .ng-select.select-input {
        border-bottom: 2px solid var(--primary-dark);
    }

    .ng-select.select-input.ng-select-single .ng-select-container .ng-value-container .ng-placeholder,
    .ng-select.select-input .ng-select-container .ng-value-container .ng-placeholder {
        color: var(--primary-grey);
    }

    .ng-select-container.ng-has-value .ng-placeholder {
        display: none;
    }

    .ng-select span {
        color: var(--primary-dark);
    }

    .ng-select.select-input .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        background-color: var(--secondary-grey);

        span {
            color: var(--primary-white);
        }
    }

    .ng-select.select-input .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
        background-color: var(--secondary-dark);
    }

    .tracking-progress {
        .tracking-item {
            &.current {
                background-color: var(--primary-grey);
                border-color: var(--primary-grey);
            }
        }
    }

    .badge {
        span {
            color: var(--primary-white);
        }
    }

    exercise-manager {
        .exercise-wrapper {
            p.rest {
                color: var(--primary-dark);
            }

            .workout-finished.feedback-wrapper .feedback-pick .form textarea {
                background-color: var(--primary-white);
                color: var(--primary-dark);
                border-bottom: 2px solid var(--primary-dark);
            }
        }
    }

    .banner {
        a {
            color: var(--primary-dark) !important;
        }
    }

    .description-box {
        h4 {
            color: var(--primary-dark) !important;
        }
    }

    app-upgrade {
        .item:not(.first) {
            p {
                color: var(--primary-dark) !important;
            }
        }

        p {
            a {
                color: var(--primary-dark) !important;
            }
        }
    }

    app-profile {
        .bottom-actions {
            h4 {
                color: var(--primary-white);
            }

            p {
                color: var(--primary-white);
            }
        }

        .info {
            p {
                color: var(--primary-dark);
            }
        }
    }

    .health-data {
        .item {
            background-color: var(--tertiary-grey) !important;
        }
    }

    .base-timer__path-elapsed {
        stroke: var(--tertiary-grey) !important;
    }

    .base-timer__path-remaining {
        color: var(--primary-color) !important;
    }

    .base-timer__label {
        color: var(--primary-dark);
    }

    .settings-field {

        h5 {
            color: var(--primary-grey);
        }

        label {
            color: var(--primary-grey);
        }

        input {
            color: var(--primary-grey);
            border-color: var(--secondary-grey);
        }
    }

    .user-input-section {
        background-color: var(--primary-white) !important;

        form {
            .user-input-round {
                background-color: var(--tertiary-grey) !important;

                .user-input {

                    .input-label {
                        color: var(--primary-dark) !important;
                    }

                    input {
                        color: var(--primary-dark) !important;
                        border-bottom: 1px solid var(--primary-dark) !important;
                    }
                }
            }
        }
    }

    .helper-box {
        background: var(--primary-white) !important;
    }
}

.dark-theme {

    ol {
        color: var(--primary-white);
    }

    .settings-field {

        h5 {
            color: var(--primary-grey);
        }

        label {
            color: var(--primary-grey);
        }

        input {
            color: var(--primary-grey);
            border-color: var(--secondary-grey);
        }
    }

}
